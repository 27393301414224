import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <PageBanner
                pageTitle="Politique de Confidentialité"
                homePageText="Menu principal"
                homePageUrl="/" 
                activePageText="Politique de Confidentialité"
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>This Privacy Policy was last updated on March 1, 2021.</i></p>

                                <h2>Privacy Policy Content Overview</h2>

                                <h3>1. What Data We Get</h3>
                                <blockquote className="blockquote">
                                    <p>We collect certain data from you directly, like information you enter yourself and data from third-party platforms you connect with like LinkedIn. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using, for technical display reasons and a better user experrience.</p>
                                </blockquote>
                                <h3>2. Data You Provide to Us</h3>
                                <p>We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.</p>
                                <h3>3. How We Get Data About You</h3>
                                <p>We use tools like cookies, analytics services. Some of these tools offer you the ability to opt out of data collection.</p>
                                <h3>4. What We Use Your Data For</h3>
                                <ol>
                                    <li>Responding to your questions and concerns;</li>
                                    <li>Sending you administrative messages and information, including messages from Peers, Recruiters, Line Managersand, Ambassadors, Experts, notifications from Users and about changes to our Service, and updates to our agreements;</li>
                                    <li>Sending push notifications to your wireless device to provide updates and other relevant messages (which you can manage from the “options” or “settings” page of the app);</li>
                                </ol>
                                <h3>5. Your Choices About the Use of Your Data</h3>
                                <p>You can choose not to provide certain data to us, but you may not be able to use certain features of the Services.</p>
                                <ul>
                                    <li>{/* To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account.*/} Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.</li>
                                    <li>The browser or device you use may allow you to control cookies and other types of local data storage. Your wireless device may also allow you to control whether location or other data is collected and shared.</li>
                                    <li>To update data you provide directly, log into your account and update your account at any time.</li>
                                </ul>





                                <h1>Privacy Policy</h1>
                                <blockquote className="blockquote">
                                    <p>PROD LLC ("us", "we", or "our") operates the  website and the Crowlr mobile application (hereinafter referred to as the "Service").</p>

                                    <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                                </blockquote>


                                    <h2>Definitions</h2>
                                    <ul>
                                        <li>
                                            <p><strong>Service</strong></p>
                                            <p>Service means the  website and the Crowlr mobile application operated by PROD LLC</p>
                                        </li>
                                        <li>
                                            <p><strong>Personal Data</strong></p>
                                            <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                                        </li>
                                        <li>
                                            <p><strong>Usage Data</strong></p>
                                            <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                                        </li>
                                        <li>
                                            <p><strong>Cookies</strong></p>
                                            <p>Cookies are small files stored on your device (computer or mobile device).</p>
                                        </li>
                                        <li>
                                            <p><strong>Data Controller</strong></p>
                                            <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
                                            <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
                                        </li>
                                        <li>
                                            <p><strong>Data Processors (or Service Providers)</strong></p>
                                            <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>
                                            <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
                                        </li>
                                        <li>
                                            <p><strong>Data Subject (or User)</strong></p>
                                            <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>
                                        </li>
                                    </ul>


                                    <h2>Information Collection and Use</h2>
                                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                                    <h3>Types of Data Collected</h3>

                                    <h4>Personal Data</h4>
                                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

                                    <ul>
                                        <li>Email address</li>    <li>First name and last name</li>    <li>Phone number</li>    <li>Address, State, Province, ZIP/Postal code, City</li>    <li>Cookies and Usage Data</li>
                                    </ul>

                                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>

                                    <h4>Usage Data</h4>

                                    <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").</p>
                                    <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                                    <p>When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

                                    <h4>Location Data</h4>
                                    <p>We may use and store information about your location if you give us permission to do so ("Location Data"). We use this data to provide features of our Service, to improve and customise our Service.</p>
                                    <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>

                                    <h4>Tracking & Cookies Data</h4>
                                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p>
                                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                    <p>Examples of Cookies we use:</p>
                                    <ul>
                                        <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
                                        <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                                        <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
                                    </ul>

                                    <h2>Use of Data</h2>
                                    <p>PROD LLC uses the collected data for various purposes:</p>
                                    <ul>
                                        <li>To provide and maintain our Service</li>
                                        <li>To notify you about changes to our Service</li>
                                        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                                        <li>To provide customer support</li>
                                        <li>To gather analysis or valuable information so that we can improve our Service</li>
                                        <li>To monitor the usage of our Service</li>
                                        <li>To detect, prevent and address technical issues</li>
                                        <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li></ul>


                                    <h2>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h2>
                                    <p>If you are from the European Economic Area (EEA), PROD LLC legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
                                    <p>PROD LLC may process your Personal Data because:</p>
                                    <ul>
                                        <li>We need to perform a contract with you</li>
                                        <li>You have given us permission to do so</li>
                                        <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
                                        <li>To comply with the law</li>
                                    </ul>


                                    <h2>Retention of Data</h2>
                                    <p>PROD LLC will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
                                    <p>PROD LLC will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</p>

                                    <h2>Transfer of Data</h2>
                                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                                    <p>If you are located outside Switzerland and choose to provide information to us, please note that we transfer the data, including Personal Data, to Switzerland and process it there.</p>
                                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                    <p>PROD LLC will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                                    <h2>Disclosure of Data</h2>
                                    <h3>Business Transaction</h3>
                                    <p>If PROD LLC is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

                                    <h3>Disclosure for Law Enforcement</h3>
                                    <p>Under certain circumstances, PROD LLC may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                                    <h3>Legal Requirements</h3>
                                    <p>PROD LLC may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                                    <ul>
                                        <li>To comply with a legal obligation</li>
                                        <li>To protect and defend the rights or property of PROD LLC</li>
                                        <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                                        <li>To protect the personal safety of users of the Service or the public</li>
                                        <li>To protect against legal liability</li>
                                    </ul>

                                    <h2>Security of Data</h2>
                                    <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                                    <h2>Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</h2>
                                    <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                                    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

                                    <h2>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h2>
                                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. PROD LLC aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</p>
                                    <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                                    <p>In certain circumstances, you have the following data protection rights:</p>
                                    <ul>
                                        <li>
                                            <p><strong>The right to access, update or delete the information we have on you.</strong> Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</p>
                                        </li>
                                        <li>
                                            <p><strong>The right of rectification.</strong> You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
                                        </li>
                                        <li>
                                            <p><strong>The right to object.</strong> You have the right to object to our processing of your Personal Data.</p>
                                        </li>
                                        <li>
                                            <p><strong>The right of restriction.</strong> You have the right to request that we restrict the processing of your personal information.</p>
                                        </li>
                                        <li>
                                            <p><strong>The right to data portability.</strong> You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</p>
                                        </li>
                                        <li>
                                            <p><strong>The right to withdraw consent.</strong> You also have the right to withdraw your consent at any time where PROD LLC relied on your consent to process your personal information.</p>
                                        </li>
                                    </ul>
                                    <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

                                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

                                    <h2>Service Providers</h2>
                                    <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
                                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                                    <h3>Analytics</h3>
                                    <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
                                    <ul>
                                        <li>
                                            <p><strong>Google Analytics</strong></p>
                                            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
                                            <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                        </li>
                                        <li>
                                            <p><strong>Firebase</strong></p>
                                            <p>Firebase is analytics service provided by Google Inc.</p>
                                            <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                            <p>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.</p>
                                            <p>For more information on what type of information Firebase collects, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                        </li>
                                    </ul>






                                    <h2>Links to Other Sites</h2>
                                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


                                    <h2>Children's Privacy</h2>
                                    <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>


                                    <h2>Changes to This Privacy Policy</h2>
                                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                                    <h2>Contact Us</h2>
                                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                                    <ul>
                                        <li>By email: contact@crowlr.com</li>
                                        <li>By visiting this page on our website: https://www.crowlr.com/</li>

                                    </ul>






                                <h1>Politique de Confidentialité</h1>
                                <blockquote className="blockquote">
                                <p>PROD LLC ("nous", "notre", "nos") exploite le site web  et l'application mobile Crowlr (ci-après désignés par le terme "Service").</p>

                                <p>Cette page vous explique nos politiques en matière de collecte, d'utilisation et de communication des données à caractère personnel lorsque vous utilisez notre Service ainsi que les choix qui s'offrent à vous en ce qui concerne ces données.</p>

                                <p>Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous consentez à la collecte et à l'utilisation d'informations conformément à la présente politique. Sauf définition contraire dans la présente Politique de Confidentialité, les termes utilisés dans la présente Politique de Confidentialité ont la même signification que dans nos Conditions Générales.</p>
                                </blockquote>

                                <h2>Définitions</h2>
                                <ul>
                                    <li>
                                        <p><strong>Service</strong></p>
                                        <p>Par Service on entend le site web  et l'application mobile Crowlr exploités par PROD LLC</p>
                                    </li>
                                    <li>
                                        <p><strong>Données à caractère personnel</strong></p>
                                        <p>Données à Caractère Personnel désigne des données concernant un individu vivant qui peut être identifié à partir de ces données (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).</p>
                                    </li>
                                    <li>
                                        <p><strong>Données d'Utilisation</strong></p>
                                        <p>Les Données d'Utilisation sont recueillies automatiquement et sont générées soit par l'utilisation du Service, soit par l'infrastructure du Service proprement dite (par exemple, durée de consultation d'une page).</p>
                                    </li>
                                    <li>
                                        <p><strong>Cookies</strong></p>
                                        <p>Les cookies sont de petits fichiers enregistrés sur votre dispositif (ordinateur ou dispositif mobile).</p>
                                    </li>
                                    <li>
                                        <p><strong>Responsable du Traitement</strong></p>
                                        <p>Responsable du Traitement désigne la personne physique ou morale (seule, ou conjointement ou en commun avec d'autres personnes) qui détermine les finalités et les modalités selon lesquelles toutes les données personnelles sont traitées ou doivent l'être.</p>
                                        <p>Aux fins de la présente Politique de Confidentialité, nous sommes un Responsable du Traitement de vos données à caractère personnel.</p>
                                    </li>
                                    <li>
                                        <p><strong>Sous-Traitants (ou Prestataires de Services)</strong></p>
                                        <p>Le terme Sous-Traitant (ou Prestataire de Service) désigne toute personne physique ou morale qui traite les données au nom du Responsable du Traitement.</p>
                                        <p>Il se peut que nous fassions appel aux services de plusieurs Prestataires de Services afin de traiter vos données plus efficacement.</p>
                                    </li>
                                    <li>
                                        <p><strong>Personne Concernée (ou Utilisateur)</strong></p>
                                        <p>Par Personne Concernée, on entend toute personne vivante qui utilise notre Service et est le sujet de Données à Caractère Personnel. </p>
                                    </li>
                                </ul>


                                <h2>Collecte et utilisation des données</h2>
                                <p>Nous recueillons plusieurs types de données à différentes fins en vue de vous fournir notre Service et de l'améliorer.</p>

                                <h3>Types de données recueillies</h3>

                                <h4>Données à Caractère Personnel</h4>
                                <p>Lorsque vous utilisez notre Service, il est possible que nous vous demandions de nous fournir certaines données personnelles nominatives qui peuvent servir à vous contacter ou à vous identifier ("Données à Caractère Personnel"). Les données personnelles nominatives peuvent comprendre, mais de manière non limitative:</p>

                                <ul>
                                    <li>Adresse e-mail</li>    <li>Prénom et nom de famille</li>    <li>Numéro de téléphone</li>    <li>Adresse, ville, province, état, code postal</li>    <li>Cookies et Données d'Utilisation</li>
                                </ul>

                                <p>Nous pouvons utiliser vos Données à Caractère Personnel pour vous envoyer des bulletins d'information, des communications commerciales ou des promotions et d'autres informations susceptibles de vous intéresser. Vous pouvez choisir de ne recevoir aucune communication de notre part ou de ne recevoir que certaines communications en cliquant sur le lien de désabonnement ou en suivant les instructions qui figurent dans chacun des e-mails que nous envoyons, ou en nous contactant.</p>

                                <h4>Données d'Utilisation</h4>

                                <p>Nous pouvons également recueillir les informations que votre navigateur envoie chaque fois que vous consultez notre Service ou que vous accédez au Service par le biais d'un dispositif mobile ("Données d'Utilisation").</p>
                                <p>Ces Données d'Utilisation peuvent comprendre des informations telles que l'adresse de protocole Internet (c.-à-d. l'adresse IP) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre Service que vous consultez, la date et l'heure de votre visite, le temps passé sur ces pages, les identifiants uniques de dispositifs ainsi que d'autres données de diagnostic.</p>
                                <p>Lorsque vous accédez au Service au moyen d'un dispositif mobile, ces Données d'Utilisation peuvent comprendre des informations telles que le type de dispositif mobile que vous utilisez, l'identifiant unique de votre dispositif mobile, l'adresse IP de votre dispositif mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de dispositifs ainsi que d'autres données de diagnostic.</p>

                                <h4>Données de localisation</h4>
                                <p>Nous pouvons utiliser et stocker des données concernant votre position géographique si vous nous y autorisez ("Données de Localisation"). Nous utilisons ces données pour fournir les fonctionnalités de notre Service et pour améliorer et personnaliser notre Service.</p>
                                <p>Lorsque vous utilisez notre Service, vous pouvez activer ou désactiver les services de localisation à tout moment en utilisant les paramètres de votre dispositif.</p>

                                <h4>Suivi et données de cookies</h4>
                                <p>Nous avons recours à des cookies et à d'autres technologies de suivi similaires pour effectuer un suivi des activités effectuées dans notre Service, et nous conservons certaines informations.</p>
                                <p>Les cookies sont des fichiers à faible volume de données pouvant comporter un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur depuis un site web et sont stockés sur votre dispositif. D'autres technologies de suivi telles que les pixels, les balises et les scripts sont également utilisées pour recueillir et suivre des informations et afin d'améliorer et d'analyser notre Service.</p>
                                <p>Vous pouvez demander à votre navigateur de refuser tous les cookies ou de vous avertir lorsqu'un cookie est envoyé. Toutefois, si vous n'acceptez pas les cookies, il se peut que vous ne puissiez pas utiliser certains éléments de notre Service.</p>
                                <p>Exemples de cookies que nous utilisons :</p>
                                <ul>
                                    <li><strong>Cookies de Session.</strong> Nous utilisons des Cookies de Session pour faire fonctionner notre Service.</li>
                                    <li><strong>Cookies de Préférences.</strong> Nous utilisons des Cookies de Préférences pour mémoriser vos préférences et vos différents paramètres.</li>
                                    <li><strong>Cookies de Sécurité.</strong> Nous utilisons des Cookies de Sécurité pour des raisons de sécurité.</li>
                                </ul>

                                <h2>Utilisation des données</h2>
                                <p>PROD LLC utilise les données recueillies à des fins diverses:</p>
                                <ul>
                                    <li>Pour fournir et assurer notre Service</li>
                                    <li>Pour vous faire part des changements apportés à notre Service</li>
                                    <li>Pour vous permettre d'utiliser les fonctions interactives de notre Service quand vous le souhaitez</li>
                                    <li>Pour assurer l'assistance client</li>
                                    <li>Pour recueillir des données précieuses ou d'analyses qui nous permettront d'améliorer notre Service</li>
                                    <li>Pour contrôler l'utilisation de notre Service</li>
                                    <li>Pour détecter, prévenir et régler les problèmes techniques</li>
                                    <li>Pour vous faire part de l'actualité, d'offres spéciales et d'informations d'ordre général concernant d'autres marchandises, services et événements que nous proposons et qui sont similaires à ceux que vous avez déjà achetés ou au sujet desquels vous vous êtes déjà renseigné, sauf si vous avez déjà indiqué que vous ne voulez pas recevoir d'informations de cette nature.</li></ul>


                                <h2>Fondement juridique du traitement des données à caractère personnel en vertu du Règlement Général relatif à la Protection des Données (RGPD)</h2>
                                <p>Si vous résidez dans l'Espace économique européen (EEE), le fondement juridique de PROD LLC en ce qui concerne la collecte et l'utilisation des données personnelles décrites dans la présente Politique de Confidentialité dépend des Données à Caractère Personnel que nous recueillons et du contexte précis dans lequel nous les recueillons.</p>
                                <p>PROD LLC peut traiter vos Données à Caractère Personnel:</p>
                                <ul>
                                    <li>Parce que nous avons besoin d'exécuter un contrat passé avec vous</li>
                                    <li>Parce que vous nous avez autorisés à le faire</li>
                                    <li>Parce que nous avons un intérêt légitime à effectuer ce traitement et que vos droits ne priment pas sur cet intérêt légitime</li>
                                    <li>Pour respecter la loi</li>
                                </ul>


                                <h2>Conservation des données</h2>
                                <p>PROD LLC ne conservera vos Données à Caractère Personnel que tant que cela sera nécessaire aux fins stipulées dans la présente Politique de Confidentialité. Nous conserverons et utiliserons vos Données à Caractère Personnel dans la mesure où cela sera nécessaire pour que nous puissions nous acquitter de nos obligations légales (par exemple, pour respecter la législation en vigueur), résoudre des différends et appliquer nos accords et nos politiques.</p>
                                <p>PROD LLC conservera également les Données d'Utilisation à des fins d'analyses internes. Les Données d'Utilisation sont généralement conservées pour une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer les fonctionnalités de notre Service, ou si nous sommes légalement tenus de conserver ces données plus longtemps.</p>

                                <h2>Transfert des données</h2>
                                <p>Les informations vous concernant, notamment vos Données à Caractère Personnel, peuvent être transférées de votre région, province, pays, ou autre division territoriale vers des ordinateurs – et stockées sur ces derniers – situés à un endroit où la législation relative à la protection des données diffère de celle du territoire où vous résidez.</p>
                                <p>Si vous résidez hors de Suisse et que vous choisissez de nous communiquer des informations, sachez que nous transférons les données, y compris les Données à Caractère Personnel, vers la Suisse et que nous les y traitons.</p>
                                <p>En acceptant la présente Politique de Confidentialité puis en soumettant ces informations, vous consentez à ce transfert.</p>
                                <p>PROD LLC prendra toutes les mesures raisonnablement nécessaires pour faire en sorte que vos données soient traitées de manière sécurisée et conformément à la présente Politique de Confidentialité et vos Données à Caractère Personnel ne seront transférées vers aucune organisation ni aucun pays à moins que des contrôles adéquats ne soient en place, notamment en ce qui concerne la sécurité de vos données et d'autres données personnelles.</p>

                                <h2>Communication de données</h2>
                                <h3>Opération commerciale</h3>
                                <p>Si PROD LLC est impliquée dans une fusion, une acquisition ou une vente d'actifs, il se peut que vos Données à Caractère Personnel soient transférées. Avant que vos Données à Caractère Personnel ne soient transférées et ne soient soumises à une Politique de Confidentialité différente, nous vous en aviserons.</p>

                                <h3>Communication de données aux forces de l'ordre</h3>
                                <p>Dans certaines circonstances, PROD LLC peut être tenue de communiquer vos Données à Caractère Personnel si la loi l'exige ou en réponse à des demandes valides émanant de pouvoirs publics (par ex. un tribunal ou un organisme gouvernemental).</p>

                                <h3>Exigences légales</h3>
                                <p>PROD LLC peut communiquer vos Données à Caractère Personnel si elle estime de bonne foi que cela est nécessaire pour:</p>
                                <ul>
                                    <li>S'acquitter d'une obligation légale</li>
                                    <li>Protéger et défendre les droits ou les biens de PROD LLC</li>
                                    <li>Prévenir d'éventuels actes répréhensibles ou enquêter sur de tels actes dans le cadre du Service </li>
                                    <li>Assurer la sécurité personnelle des utilisateurs du Service ou du public</li>
                                    <li>Se protéger contre la responsabilité civile</li>
                                </ul>

                                <h2>Sécurité des données</h2>
                                <p>La sécurité de vos données nous tient à cœur. Toutefois, n'oubliez pas qu'aucune méthode de transmission de données par Internet ou méthode de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des méthodes commercialement acceptables pour protéger vos Données à Caractère Personnel, nous ne pouvons pas leur garantir une sécurité absolue.</p>

                                <h2>Signaux "Ne pas me suivre"</h2>
                                <p>Nous ne prenons pas en charge "Ne pas me suivre" ("NPS"). "Ne pas me suivre" est une préférence que vous pouvez définir dans votre navigateur web pour indiquer aux sites web que vous ne voulez pas être suivi.</p>
                                <p>Vous pouvez activer ou désactiver "Ne pas me suivre" en vous rendant sur la page Préférences ou Paramètres de votre navigateur web.</p>

                                <h2>Droits à la protection des données qui vous sont accordés par le Règlement Général relatif à la Protection des Données (RGPD)</h2>
                                <p>Si vous résidez dans l'Espace économique européen (EEE), vous avez certains droits en matière de protection des données. PROD LLC entend prendre des mesures raisonnables pour vous permettre de corriger, de modifier, ou de supprimer vos Données à Caractère Personnel ou d'en limiter l'utilisation.</p>
                                <p>Si vous souhaitez savoir quelles Données à Caractère Personnel nous détenons à votre sujet et voulez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.</p>
                                <p>Dans certaines circonstances, vous avez les droits suivants en matière de protection des données:</p>
                                <ul>
                                    <li>
                                        <p><strong>Le droit d'accéder aux informations que nous détenons à votre sujet, de les actualiser ou de les supprimer.</strong> Lorsque cette option est proposée, vous pouvez consulter ou actualiser vos Données à Caractère Personnel ou en demander la suppression dans la section paramètres de votre compte. Si vous ne pouvez pas effectuer ces actions vous-même, merci de nous contacter pour obtenir de l'aide.</p>
                                    </li>
                                    <li>
                                        <p><strong>Droit de rectification.</strong> Vous avez le droit de faire rectifier vos données si elles sont inexactes ou incomplètes.</p>
                                    </li>
                                    <li>
                                        <p><strong>Droit d'opposition.</strong> Vous avez le droit de vous opposer à ce que nous traitions vos Données à Caractère Personnel.</p>
                                    </li>
                                    <li>
                                        <p><strong>Droit de limitation</strong> Vous avez le droit de nous demander de limiter le traitement de vos données personnelles.</p>
                                    </li>
                                    <li>
                                        <p><strong>Le droit à la portabilité des données.</strong> Vous avez le droit de recevoir une copie des informations que nous détenons à votre sujet dans un format couramment utilisé, structuré et lisible par une machine.</p>
                                    </li>
                                    <li>
                                        <p><strong>Droit au retrait du consentement.</strong> Vous avez également le droit de retirer votre consentement à tout moment si PROD LLC s'est appuyé sur votre consentement pour traiter vos données personnelles.</p>
                                    </li>
                                </ul>
                                <p>Veuillez noter que nous pouvons vous demander de prouver votre identité avant de répondre à des demandes de cette nature.</p>

                                <p>Vous avez le droit de déposer plainte auprès d'une autorité de protection des données au sujet de notre collecte et de notre utilisation de vos Données à Caractère Personnel. Pour plus de précisions, veuillez contacter l'autorité de protection des données la plus proche de vous dans l'Espace économique européen (EEE).</p>

                                <h2>Prestataires de services</h2>
                                <p>Nous pouvons faire appel à des sociétés tierces et à des tierces personnes pour faciliter la prestation de notre Service ("Prestataires de Services"), assurer le Service en notre nom, assurer des services liés au Service ou nous aider à analyser la façon dont notre Service est utilisé.</p>
                                <p>Ces tiers n'ont accès à vos Données à Caractère Personnel que pour effectuer ces tâches en notre nom et il leur est interdit de les communiquer ou de les utiliser à quelle qu'autre fin. </p>

                                <h3>Analyses</h3>
                                <p>Nous pouvons faire appel à des Prestataires de Services tiers pour surveiller et analyser l'utilisation de notre Service.</p>
                                <ul>
                                    <li>
                                        <p><strong>Google Analytics</strong></p>
                                        <p>Google Analytics est un service d'analyse web proposé par Google qui assure le suivi du trafic d'un site web et en rend compte. Google utilise les données recueillies pour suivre et surveiller l'utilisation de notre Service. Ces données sont partagées avec d'autres services Google. Google peut utiliser les données recueillies pour contextualiser et personnaliser les annonces de son propre réseau publicitaire.</p>
                                        <p>Pour plus de précisions sur les pratiques de confidentialité de Google, merci de consulter la page web Protection de la vie privée et conditions de Google: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                    </li>
                                    <li>
                                        <p><strong>Firebase</strong></p>
                                        <p>Firebase est un service d'analyse fourni par Google Inc.</p>
                                        <p>Vous pouvez refuser certaines fonctionnalités de Firebase en utilisant les paramètres de votre dispositif mobile, par exemple ses paramètres publicitaires, ou en suivant les instructions qui figurent dans la politique de confidentialité de Google: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                        <p>Nous vous encourageons également à consulter la politique de Google relative à la protection de vos données: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.</p>
                                        <p>Pour plus de précisions sur le type de données recueillies par Firebase, merci de consulter la page web Protection de la vie privée et conditions de Google <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                    </li>
                                </ul>






                                <h2>Liens pointant vers d'autres sites</h2>
                                <p>Il se peut que notre Service contienne des liens pointant vers d'autres sites que nous n'exploitons pas. Si vous cliquez sur un lien de tiers, vous serez redirigé vers le site de ce tiers. Nous vous recommandons vivement d'examiner la politique de confidentialité de chacun des sites que vous consultez.</p>
                                <p>Nous n'avons aucun contrôle sur le contenu, les politiques ou pratiques de confidentialité des sites ou services de tiers et déclinons toute responsabilité en ce qui les concerne.</p>


                                <h2>Vie privée des enfants</h2>
                                <p>Notre Service ne s'adresse pas aux personnes de moins de 18 ans ("Enfants").</p>
                                <p>Nous ne recueillons pas sciemment de données personnelles nominatives auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre Enfant nous a communiqué des Données à Caractère Personnel, veuillez nous contacter. Si nous apprenons que nous avons recueilli des Données à Caractère Personnel auprès d'enfants sans vérifier s'il y a consentement parental, nous faisons le nécessaire pour supprimer ces informations de nos serveurs.</p>


                                <h2>Modifications de la présente Politique de Confidentialité</h2>
                                <p>Nous nous réservons le droit d'actualiser notre Politique de Confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle Politique de Confidentialité sur cette page.</p>
                                <p>Avant que la modification ne prenne effet, nous vous en informerons par e-mail et/ ou en plaçant un avis bien en vue dans notre Service et nous actualiserons la "date de prise d'effet" qui figure en haut de la présente Politique de Confidentialité.</p>
                                <p>Nous vous conseillons de consulter la présente Politique de Confidentialité périodiquement pour prendre connaissance de toute modification. Les modifications apportées à la présente Politique de Confidentialité prennent effet lorsqu'elles sont publiées sur cette page.</p>


                                <h2>Nous contacter</h2>
                                <p>Pour toute question relative à la présente Politique de Confidentialité, veuillez nous contacter:</p>
                                <ul>
                                    <li>Par courrier électronique: contact@crowlr.com</li>
                                    <li>En consultant cette page sur notre site web: https://www.crowlr.com/</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                    <div className="widget widget_insight">
                                        <ul>
                                            <li>
                                                <Link to="/about-us">
                                                    A Propos
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">
                                                    Contact
                                                </Link>
                                            </li>

                                            <li className="active">
                                                <Link to="/privacy-policy">
                                                    Politique de Confidentialité
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-of-service">
                                                    Conditions d'Utilisation
                                                </Link>
                                            </li>
                                        </ul>

                                    </div>
                                </aside>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;